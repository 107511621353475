<template>
  <div
    id="home"
    :style="{ 'background-image': 'url(' + require('@/assets/background/' + background) + ')' }"
    class="fade-in-slow"
  >
    <div
      v-if="!loading"
      class="flex flex-wrap fade-in"
      :class="{ 'mt-20 border-round': !createDate }"
    >
      <div class="flex flex-column flex-5" id="signature-and-navigation">
        <div class="dates header-card signature-header" v-if="createDate">
          <div class="expiration mb-15">
            <span class="exp-on secondary-text text-focus-in">SENT ON</span>
            <span class="expiration-date fade-in-bottom">{{ createDate }}</span>
          </div>
        </div>

        <signature
          id="signature-box"
          :class="{ 'border-round': !createDate }"
          :by="by"
          :createDate="createDate"
          :projectLogo="currentLogo"
          :isInvalid="isInvalid"
          @toggle-show-nation-wide="toggleShowNationWide"
        />
      </div>

      <div class="flex-4 fade-in" id="downloads-wrapper">
        <div class="flex flex-4 header-card downloads-header" v-if="createDate">
          <div id="navigation-container">
            <button
              v-if="projectIds.length > 1"
              class="back"
              title="Previous"
              @click="prev"
              :disabled="currentProjectPage === 0 || loading"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24px"
                viewBox="0 -960 960 960"
                width="24px"
                fill="#e8eaed"
              >
                <path d="m313-440 224 224-57 56-320-320 320-320 57 56-224 224h487v80H313Z" />
              </svg>
            </button>

            <span class="title text-focus-in">
              {{ currentProjectAlias }}
            </span>

            <div v-if="projectIds.length > 1" style="position: relative">
              <button
                class="next"
                title="Next"
                @click="next"
                :disabled="currentProjectPage === projectIds.length - 1 || loading"
              >
                <svg
                  :class="{ wiggle: currentProjectPage !== projectIds.length - 1 && !loading }"
                  xmlns="http://www.w3.org/2000/svg"
                  height="24px"
                  viewBox="0 -960 960 960"
                  width="24px"
                  fill="#e8eaed"
                >
                  <path d="M647-440H160v-80h487L423-744l57-56 320 320-320 320-57-56 224-224Z" />
                </svg>
              </button>
            </div>
          </div>
        </div>
        <downloads
          v-if="!loading"
          id="downloads"
          :class="{ 'border-round': !createDate }"
          :projectAlias="currentProjectAlias"
          :files="currentProjectFiles"
          :downloaded="currentDownloaded"
          :isDownloadedAll="currentDownloaded && currentDownloaded.every(Boolean)"
          @update-downloaded="updateDownloaded"
          @update-downloaded-all="updateDownloadedAll"
        />
      </div>
    </div>
    <my-maps
      v-show="isOpenNationWide"
      :markers="markers"
      :isInvalid="isInvalid"
      style="height: 100%; width: 100%"
      @close="isOpenNationWide = false"
    />
  </div>
</template>

<script>
import MyMaps from "@/components/MyMaps.vue";
import Downloads from "@/components/Downloads.vue";
import Signature from "@/components/Signature.vue";
import { mapState } from "vuex";

export default {
  name: "Home",

  components: {
    Signature,
    Downloads,
    MyMaps,
  },

  props: {
    loading: { type: Boolean, default: false },
    isInvalid: { type: Boolean, default: false },
    createDate: { type: String, default: "" },
    by: { type: String, default: "" },
    files: { type: Object, default: () => ({}) },
  },

  data() {
    return {
      background: `background-${Math.floor(Math.random() * 246) + 1}.jpg`,

      downloaded: {},
      isOpenNationWide: false,
      currentProjectId: null,
      currentProjectPage: 0,
    };
  },

  computed: {
    ...mapState(["falconiLogo", "markers"]),

    projectIds() {
      return Object.keys(this.files);
    },

    currentProjectFiles() {
      return this.projectIds.length ? this.files[this.currentProjectId] : [];
    },

    currentDownloaded() {
      return this.downloaded[this.currentProjectId] || [];
    },

    currentLogo() {
      const first = this.currentProjectFiles[0];
      if (!first) return this.falconiLogo;

      const result = Array.isArray(first.logo) ? first.logo[0]?.data : first.logo;

      return first.logo && result ? result : this.falconiLogo;
    },

    currentProjectAlias() {
      return this.currentProjectFiles[0] ? this.currentProjectFiles[0].projectAlias : "";
    },
  },

  watch: {
    files() {
      this.initialize();
    },
  },

  mounted() {
    this.initialize();
  },

  methods: {
    initialize() {
      if (this.files) {
        Object.entries(this.files).forEach(([key, value]) => {
          this.downloaded[key] = value.map(() => false);
        });
      }

      this.currentProjectId = this.projectIds[0];
    },

    updateDownloaded({ index, value }) {
      const currentDownloaded = this.downloaded[this.currentProjectId];
      currentDownloaded[index] = value;
      this.downloaded = { ...this.downloaded, [this.currentProjectId]: currentDownloaded };
    },

    updateDownloadedAll() {
      const currentDownloaded = this.downloaded[this.currentProjectId];
      this.downloaded = {
        ...this.downloaded,
        [this.currentProjectId]: currentDownloaded.map(() => true),
      };
    },

    next() {
      this.currentProjectPage += 1;
      this.currentProjectId = this.projectIds[this.currentProjectPage];
    },

    prev() {
      this.currentProjectPage -= 1;
      this.currentProjectId = this.projectIds[this.currentProjectPage];
    },

    toggleShowNationWide() {
      if (this.isOpenNationWide) this.isOpenNationWide = false;
      else {
        setTimeout(() => {
          this.isOpenNationWide = true;
        }, 300);
      }
    },
  },
};
</script>

<style scoped>
#home {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  object-fit: cover;
  background-repeat: no-repeat;
  background-size: cover;
}

#navigation-container-wrapper {
  flex: 1;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

#navigation-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 100%;
  width: 100%;
}

#signature-and-navigation {
  /* margin: 0 20px 20px 20px; */
  height: 80vh;
}

#signature-box {
  margin: 0 20px 20px 20px;
}

#downloads {
  height: 80vh;
}

#downloads::-webkit-scrollbar {
  width: 10px;
}
#downloads::-webkit-scrollbar-track {
  border-radius: 10px;
}

#downloads::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 20px;
}

.button-text {
  color: white;
  position: absolute;
  z-index: 2;
  margin-top: -33px;
}

.next,
.back {
  cursor: pointer;
  user-select: none;
  height: 100px;
  width: 75px;
  border: none;
  background-color: rgba(1, 1, 1, 0.1);
  flex-shrink: 0;
}

.next:disabled svg,
.back:disabled svg {
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(164deg) brightness(100%) contrast(105%);
}

.header-card {
  display: flex;
  align-items: center;
  background-color: #de4242;
  min-height: 100px;
}

.dates {
  display: flex;
  justify-content: space-between;
}

.expiration {
  align-items: center;
  display: flex;
  flex-flow: column;
  justify-content: center;
  margin: 0 auto;
}

.expiration-date {
  font-size: 1.5em;
  color: #ffffff;
  font-weight: 600;
}

.exp-on {
  font-size: 12px;
  text-align: center;
  margin-bottom: 5px;
}

.secondary-text {
  color: #ffffff;
}

.title {
  text-align: center;
  color: white;
  font-size: 16px;
  margin-bottom: 35px;
  margin: auto 10px;
}

.downloads-header {
  margin: 20px 20px 0 0px;
  border-radius: 0 10px 0 0;
  padding-left: 20px;
}

.signature-header {
  margin: 20px 0 0 20px;
  border-radius: 10px 0 0 0;
}

.border-round {
  border-radius: 10px;
}

@keyframes fade-in-out-animation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fade-in-out {
  cursor: pointer;
  animation: fade-in-out-animation 0.75s infinite;
  animation-direction: alternate-reverse;
  animation-timing-function: ease-in-out;
}

.fade-in-out:hover {
  animation: none;
}

@media screen and (max-width: 1024px) {
  #home {
    overflow: auto;
    flex-wrap: wrap;
  }

  #downloads,
  #signature-and-navigation {
    height: unset;
  }
}

@media screen and (max-width: 767px) {
  #downloads-wrapper,
  #signature-and-navigation {
    min-width: 90%;
  }

  .signature-header {
    margin: 20px 20px 0 20px;
    border-radius: 10px 10px 0 0;
  }

  .downloads-header {
    margin: 20px 20px 0 20px;
    border-radius: 10px 10px 0 0;
    padding-left: 0px;
  }
  .title {
    font-size: 12px;
  }

  .signature {
    border-radius: 10px 10px 0 0;
  }
}

@media screen and (max-width: 320px) {
  .title {
    font-size: 10px;
  }
}
</style>
